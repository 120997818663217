import {React} from 'react'
import Header, { HeaderNav, Menu } from '../Components/Header/Header'
import { Link } from 'react-router-dom'
import SocialIcons from '../Components/SocialIcon/SocialIcons'
import { Navbar, Col, Container, Row } from 'react-bootstrap'

// Data
const HeaderSocialIconsData = [
	{ color: "#828282", link: "https://www.instagram.com/seoulinternationalgardenshow", icon: "fab fa-instagram" },
	{ color: "#828282", link: "https://www.youtube.com/Seoulgardenshow", icon: "fab fa-youtube" }
]

const MainHeader = (props) => {
	return (
		<>
			{/* Header Start */}
			<Header topSpace={{ md: true }} type="reverse-scroll">
				<HeaderNav fluid="fluid" theme="light" bg="white" expand="lg" containerClass="md:p-0" className="py-[0px] px-[35px] md:px-[15px] md:py-[20px] sm:px-0">
				{/*
				<HeaderNav fluid="fluid" theme="light" bg="white" menu="light" containerClass="sm:px-0" className="px-[35px] py-[0px] md:px-0">
				*/}
					<Col className="col-5 col-lg-2 ps-lg-0 me-auto me-lg-0">
						<Link aria-label="header logo" className="flex items-center" to="/">
							<Navbar.Brand className="inline-block p-0 m-0">
								<img className="default-logo" width="111" height="36" loading="lazy" src='/assets/img/logo-black.png' data-rjs='/assets/img/logo-black@2x.webp' alt='logo' />
								<img className="alt-logo" width="111" height="36" loading="lazy" src='/assets/img/logo-black.png' data-rjs='/assets/img/logo-black@2x.webp' alt='logo' />
								<img className="mobile-logo" width="111" height="36" loading="lazy" src='/assets/img/logo-black.png' data-rjs='/assets/img/logo-black@2x.webp' alt='logo' />
							</Navbar.Brand>
						</Link>
					</Col>
					<Navbar.Toggle className="md:mr-[17px]">
						<span className="navbar-toggler-line"></span>
						<span className="navbar-toggler-line"></span>
						<span className="navbar-toggler-line"></span>
						<span className="navbar-toggler-line"></span>
					</Navbar.Toggle>
					<Navbar.Collapse className="justify-center">
						<Menu {...props} />
					</Navbar.Collapse>
					<Col className="col-auto col-lg-2 text-end px-lg-0">
						<SocialIcons theme="social-icon-style-01 block text-end" iconColor="dark" size="xs" data={HeaderSocialIconsData} />
					</Col>
				</HeaderNav>
			</Header>
			{/* Header End */}
		</>
	)
}

export default MainHeader
