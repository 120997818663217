import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop"

/* 
* Sigs 2024
*/
import MainLayout from './Sigs/MainLayout'
const SigsHome = lazy(() => import("./Sigs/Home"))
const About = lazy(() => import("./Sigs/About"))
const Notice = lazy(() => import('./Sigs/Notice'))
const InvitationalGarden = lazy(() => import('./Sigs/InvitationalGarden'))
// const ShowGardenA = lazy(() => import('./Sigs/ShowGardenA'))
// const ShowGardenA = lazy(() => import('./Sigs/ShowGardenA2'))
const ShowGardenA = lazy(() => import('./Sigs/ShowGardenA2'))
const ShowGardenB = lazy(() => import('./Sigs/ShowGardenB2'))
const StudentGarden = lazy(() => import('./Sigs/StudentGarden'))
const CitizenGarden = lazy(() => import('./Sigs/CitizenGarden'))
const CorporateSponsorGarden = lazy(() => import('./Sigs/CorporateSponsorGarden'))
const PublicAgencyGarden = lazy(() => import('./Sigs/PublicAgencyGarden'))
const GardenCultureProgram = lazy(() => import('./Sigs/GardenCultureProgram'))
const GardenCenter = lazy(() => import('./Sigs/GardenCenter'))
const GardenDetailPage = lazy(() => import("./Sigs/GardenDetailPage"))
const NoticeDetailPage = lazy(() => import("./Sigs/NoticeDetailPage"))

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false
  })
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener('load', retina(document.querySelectorAll('img')));
  }, [])

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then(module => {
        module.SetHeaderMenuPos()
        module.setDocumentFullHeight()
      })
    }, 1000);
  }, [location])

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
					<MainLayout>
						<main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
							<ScrollToTopButton />
							<AnimatePresence exitBeforeEnter>
								<Suspense fallback={<></>}>
									<Routes>

										{/* Sigs 2024 */}
										<Route path="/" element={<SigsHome />} />
										<Route path="/about" element={<About style={{ "--base-color": "#0038e3" }} />} />
										<Route path="/notice" element={<Notice style={{ "--base-color": "#0038e3" }} />} />
										<Route path="/invitational-garden" element={<InvitationalGarden style={{ "--base-color": "#0038e3" }} />} /> 
										<Route path="/show-garden-a" element={<ShowGardenA style={{ "--base-color": "#0038e3" }} />} />
										<Route path="/show-garden-b" element={<ShowGardenB style={{ "--base-color": "#0038e3" }} />} />
										<Route path="/student-garden" element={<StudentGarden style={{ "--base-color": "#0038e3" }} />} />
										<Route path="/citizen-garden" element={<CitizenGarden style={{ "--base-color": "#0038e3" }} />} />

										<Route path="/corporate-sponsor-garden" element={<CorporateSponsorGarden style={{ "--base-color": "#0038e3" }} />} />
										<Route path="/public-agency-garden" element={<PublicAgencyGarden style={{ "--base-color": "#0038e3" }} />} />

										<Route path="/garden-culture-program" element={<GardenCultureProgram style={{ "--base-color": "#0038e3" }} />} />
										<Route path="/garden-center" element={<GardenCenter style={{ "--base-color": "#0038e3" }} />} />

										<Route path="gardens">
											<Route path=":id" element={<GardenDetailPage style={{ "--base-color": "#0038e3" }} />} />
										</Route>

										<Route path="notice">
											<Route path=":id" element={<NoticeDetailPage style={{ "--base-color": "#0038e3" }} />} />
										</Route>

									</Routes>
								</Suspense>
							</AnimatePresence>
						</main>
					</MainLayout>
        }
      </div>
    </GlobalContext.Provider>
  )
}

export default App;
