const HeaderData = [
    {
        title: 'SIGS 2024',
        dropdown: [
            {
                title: 'About',
								link: '/about'
						},
            {
                title: 'Notice',
								link: '/notice'
						},
				]
		},
    {
        title: 'Gardens',
        dropdown: [
            {
								title: 'Invitational Garden',
								link: '/invitational-garden'
						},
						{
								title: 'Show Garden A & B',
                dropdown: [
                    {
                        title: 'Show Garden A',
                        link: '/show-garden-a'
                    },
                    {
                        title: 'Show Garden B',
                        link: '/show-garden-b'
                    },
                ]
            },
            {
								title: 'Student Garden',
								link: '/student-garden'
						},
						{
								title: 'Citizen Garden',
								link: '/citizen-garden'
						},
						// {
						// 		title: 'Corporate Sponsor Garden',
						// 		link: '/corporate-sponsor-garden'
						// },
						// {
						// 		title: 'Public Agency Garden',
						// 		link: '/public-agency-garden'
						// },
        ]
    },
    {
        title: 'Garden Culture Program',
				link: '/garden-culture-program'
		},
    {
        title: 'Garden Center',
				link: '/garden-center'
		},
]

export default HeaderData
