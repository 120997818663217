import {React} from 'react'
import { Link } from 'react-router-dom'
import SocialIcons from '../Components/SocialIcon/SocialIcons'
import { Footer } from '../Components/Footers/Footer'
import { Col, Container, Row } from 'react-bootstrap'

// Data
const FooterSocialIconData = [
	{ color: "#828282", link: "https://www.instagram.com/seoulinternationalgardenshow", icon: "fab fa-instagram" },
	{ color: "#828282", link: "https://www.youtube.com/Seoulgardenshow", icon: "fab fa-youtube" }
]

const MainFooter = () => {
	return (
		<>
		{/* Footer Start */}
		<Footer parallax={{ desktop: false, lg: false }} className="py-[6%] border-t border-mediumgray xs:py-[13%]">
			<Container>
				<Row>
					<Col className="xl:text-left md:text-center last:m-0 md:mt-[25px]" lg={{ span: 3, order: 1 }} md={{ span: 12, order: 3 }} xs={{ span: 12, order: 3 }}>
						<Link aria-label="footer home link" to="/" className="text-slateblue mb-[10px] md:mb-[5px] md:mt-0 inline-block">
							<img src="/assets/img/logo-black.png" alt="logo" width="111%" height={36} />
						</Link>
						<p>© Copyright <a aria-label="footer litho link" href="/" className="underline underline-offset-4 text-black font-medium hover:text-white">SIGS 2024</a></p>
					</Col>
					<Col lg={{ order: 2 }} md={{ span: 6, order: 1 }} xs={{ span: 12, order: 1 }} className="text-center items-center flex flex-col md:items-start sm:mb-[50px] sm:items-center xs:mb-[30px]">
						<span className="mb-[25px] block sm:w-full md:text-start md:w-4/5 sm:text-center leading-none md:leading-normal">
							<span className="text-black font-medium">
								Ttukseom Hangang Park, Seoul, Republic of Korea
							</span>
							<div className="mt-3">Location: 139, Gangbyunbukro, Gwangjin-gu, Seoul (704-1, Jayang-dong)</div>
						</span>
					</Col>
					<Col className="text-center md:text-right sm:mb-[10px]" lg={{ span: 3, order: 3 }} md={{ span: 6, order: 2 }} xs={{ span: 12, order: 2 }} >
						<span className="mb-[15px] block text-right sm:text-center">Connect with social</span>
						<SocialIcons theme="social-icon-style-01" className="justify-end sm:justify-center" size="xs" iconColor="dark" data={FooterSocialIconData} />
					</Col>
				</Row>
			</Container>
		</Footer>
		{/* Footer End */}
		</>
	)
}

export default MainFooter
